import Logo from 'images/logo.svg'

import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import HeaderNav from './HeaderNav';
import classes from './Header.module.scss';

const Header:FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleClick = () => {
    if (pathname !== '/') {
      navigate('/');
    }
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  }

  return (
    <header className={classes.root}>
      <div className={`container ${classes.container}`}>
        <div className={classes.logo} onClick={handleClick}>
          <img src={Logo} alt="MouseOver" />
        </div>
        <HeaderNav />
      </div>
    </header>
  );
}

export default Header;
