import React from 'react';
// Styles
import classes from './styles.module.scss';
// Assets
import Image from './image.jpg';

const Tips:React.FC = () => {
  return (
    <div className={classes.root}>
      <div className={`container ${classes.container}`}>
        <div className={classes.text}>
          <h2>Tips for designing a modern office</h2>
          <div className={classes.tip}>
            <h4>1</h4>
            <p>Open Space and Natural Light</p>
          </div>
          <div className={classes.tip}>
            <h4>2</h4>
            <p>Biophilic anf Ergonomic Design</p>
          </div>
          <div className={classes.tip}>
            <h4>3</h4>
            <p>Branding and Identity</p>
          </div>
          <div className={classes.tip}>
            <h4>4</h4>
            <p>Technology Integration</p>
          </div>
          <div className={classes.tip}>
            <h4>5</h4>
            <p>Breakout Zones</p>
          </div>
        </div>
        <img src={Image} alt="" className={classes.image} />
      </div>
    </div>
  )
}

export default Tips;
