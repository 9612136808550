import classes from './styles.module.scss';

import { FC } from 'react';
import Icon, { IconTypes } from 'components/Icon';
import Dots from 'components/Dots';

interface IExperience {
  label: string;
  icon: IconTypes;
}

const experience:IExperience[] = [
  { label: 'Digital Marketing', icon: 'Laptop' },
  { label: 'Ecommerce', icon: 'ShoppingCart' },
  { label: 'Hospitality', icon: 'HouseLine' },
  { label: 'Research and development', icon: 'MagnifyingGlass' },
  { label: 'Healthcare', icon: 'Heartbeat' },
  { label: 'Insurance', icon: 'UmbrellaSimple' }
];

const Abount:FC = () => {
  return (
    <div className={classes.root} data-container="about">
      <div className={`container ${classes.container}`}>
        <div className={classes.experience}>
          <div>
            <h3 className={classes.experienceTitle}>Our experience</h3>
            <div className={classes.experienceGroup}>
              {experience.map((exp:IExperience) => (
                <div
                  key={`experience-item-${exp.label}`}
                  className={classes.experienceItem}
                >
                  <Icon className={classes.experienceItemIcon} icon={exp.icon} />
                  <span className={classes.experienceItemLabel}>{exp.label}</span>
                </div>
              ))}
            </div>
          </div>
          <div className={classes.mobileDots}>
            <Dots id="aboutUsMobileDots" matrix={[[1,1,1,1,1,1,1,1], [1,1,1,1,1,1,1,1]]} />
          </div>
          <div className={classes.desktopDots}>
            <Dots id="aboutUsDesktopDots" matrix={[[1,1,1,1,1,1,1], [1,1,1,1,1,1,1], [1,1,1,1,1,1,1]]} />
          </div>
        </div>
        <span className={classes.divider}></span>
        <div className={classes.info}>
          <h3 className="subtitle">WE GO BIG BY GOING SMALL</h3>
          <h2 className="title">Who <span className="highlight">we are?</span></h2>
          <p className="text">We are a team of tech-professionals with decade of experience working in huge IT companies with fortune 500 customers but now joined together as MouseOver being extremely agile and flexible and ready to provide you top grade services for you business.</p>
          <p className="text mb-0">We can be with you from day one providing full range of services starting with requirements collection and finishing by launching your product live.</p>
        </div>
      </div>
    </div>
  )
}

export default Abount;
