import React from 'react';
// Styles
import classes from './styles.module.scss';
// Assets
import BannerImage from './banner.jpg';

const Banner:React.FC = () => {
  return (
    <div className={classes.root} style={{ backgroundImage: `url(${BannerImage})` }}>
      <div className={`${classes.container}`}>
        <div className={classes.bannerText}>
          <h2>Elemental Synergy: Harmonizing Nature and Tech</h2>
          <p>Enter Elemental Synergy – an office that welcomes natural light through panoramic windows, fusing the warmth of wood with the contemporary touch of metal, and echoing an Eastern cultural essence in its design.</p>
        </div>
      </div>
    </div>
  )
}

export default Banner;
