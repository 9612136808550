import { FC, Fragment } from 'react';
// Components
import AppRouting from './AppRouting';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Cookies from 'components/Cookies';

const App:FC = () => {
  return (
    <Fragment>
      <Header />
      <AppRouting />
      <Footer />
      <Cookies />
    </Fragment>
  );
}

export default App;
