import classes from './Header.module.scss';

import { FC, useCallback, useState, useLayoutEffect, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// Components
import Icon from 'components/Icon';
// Hooks
import useScrollIntoView from 'hooks/useScrollIntoView';

interface INavLink {
  id: string;
  label: string;
}

const navLinks:INavLink[] = [
  { id: 'banner', label: 'Banner' },
  { id: 'about', label: 'About' },
  // { id: '#why-us', label: 'Why Us' },
  { id: 'services', label: 'Services' },
  { id: 'projects', label: 'Projects' },
  { id: 'trust-us', label: 'Trust Us' },
  { id: 'contact-us', label: 'Contact Us' },
];

const options = {
  root: null,
  rootMargin: '0px',
  threshold: 1
};

const HeaderNav:FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { scrollTo } = useScrollIntoView();

  const [ openMenu, setOpenMenu ] = useState(false);
  const [ activeMenuItem, setActiveMenuItem ] = useState<string | null>(null);

  const observerCallback = useCallback((entries:any) => {
    entries.forEach((entry:any, index:number) => {
      if ( entry.isIntersecting ){
        const id = entry.target.dataset.container;
        if ( id ) setActiveMenuItem(id);
      }
    });
  }, []);

  useLayoutEffect(() => {
    if (pathname !== '/') {
      setActiveMenuItem(null);
      return;
    }

    const blocks = navLinks.map((navLink:INavLink) => document.querySelector(`[data-container="${navLink.id}"]`));
    const observer = new IntersectionObserver(observerCallback, options);
    blocks.forEach((block:Element | null) => {
      if ( block ) observer.observe(block)
    });
    return () => {
      observer.disconnect();
    }
    // eslint-disable-next-line
  }, [pathname]);

  const toggleMenu = () => setOpenMenu(!openMenu);

  const handleClick = (containerName:string) => {
    if (pathname !== '/') {
      navigate(`/`);
    }
    setTimeout(() => {
      toggleMenu();
      scrollTo(containerName);
    }, 100)
  }

  useEffect(() => {
    const body = document.getElementsByTagName('body');
    body[0].className = openMenu ? 'page' : '';
  }, [openMenu]);

  return (
    <div className={classes.navWrapper}>
      <div className={`${classes.nav} ${ openMenu ? classes.navIsOpen : '' }`}>
        {navLinks.map((navLink:INavLink) => (
          <li
            key={`nav-link-item-${navLink.id}`}
            className={`
              ${classes.navItem}
              ${navLink.id === activeMenuItem ? classes.navItemActive : ''}
              ${navLink.id === 'banner' ? 'hidden' : ''}
            `}
            onClick={() => handleClick(navLink.id)}
          >
            {navLink.label}
          </li>
        ))}
      </div>
      <button title='Open menu' className={`button button-icon ${classes.buttonIcon}`} onClick={toggleMenu} >
        <Icon icon={openMenu ? 'Close' : 'MenuFilled'} />
      </button>
    </div>
  )
}

export default HeaderNav;
