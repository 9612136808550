import classes from './styles.module.scss';

import { FC, Fragment } from 'react';
import Icon from 'components/Icon';

type Props = {
  onClose: () => void;
  image: string;
}

const ImageDialog:FC<Props> = ({ onClose, image }) => {
  return (
    <Fragment>
      <div className={classes.backdrop}></div>
      <div className={classes.dialog}>
        <div className={classes.dialogContent}>
          <button
            title='Close dialog'
            className={`button button-icon ${classes.dialogCloseButton}`}
            onClick={onClose}
          >
            <Icon icon="Close" />
          </button>
          <img src={image} className={classes.image} alt="" />
        </div>
      </div>
    </Fragment>
  )
}

export default ImageDialog;
