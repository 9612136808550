import React from 'react';
// Styles
import classes from './styles.module.scss';
// Assets
import Image from './image.jpg';

const Benefits:React.FC = () => {
  return (
    <div className={classes.root}>
      <div className={`container ${classes.container}`}>
        <img src={Image} alt="" className={classes.image} />
        <div className={classes.text}>
          <h2>Highly efficient office layout</h2>
          <p>Comfortable and efficient workplaces are key factors in productivity, offering environments where employees thrive through ergonomic designs and streamlined workflows.</p>
          <p>By combining the backup Starlink internet and the charging station, we have created a reliable and self-sufficient workspace</p>
        </div>
      </div>
    </div>
  )
}

export default Benefits;
