import { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
// Pages
import HomePage from 'pages/HomePage';
import OfficePage from 'pages/OfficePage';

const AppRouting:FC = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/office" element={<OfficePage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default AppRouting;
