import classes from './styles.module.scss';

import { FC } from 'react';
import Icon, { IconTypes } from 'components/Icon';
import Dots from 'components/Dots';

interface IService {
  icon: IconTypes;
  label: string;
}

const services:IService[] = [
  { label: 'Web applications development', icon: 'Desktop' },
  { label: 'Product engineering', icon: 'Cube' },
  { label: 'Mobile applications development', icon: 'DeviceMobile' },
  { label: 'UI/UX design', icon: 'Stack' },
  { label: 'Dev ops (AWS / Azure / GCloud)', icon: 'Gear' },
  { label: 'Big data solutions and ML', icon: 'BigData' },
  { label: 'Cloud cost optimization for big data', icon: 'CloudCheck' },
];

const WhoWeAre:FC = () => {
  return (
    <div className={classes.root} data-container="services">
      <div className="container">
        <div className={classes.wrapper}>
          <div className={classes.wrapperLeft}>
            <h3 className="subtitle">TOP GRADE QUALITY</h3>
            <h2 className={`title ${classes.title}`}>
              Our <span className="highlight">services.</span>
            </h2>
            <div className={classes.laptopDots}>
              <Dots id="serviceLaptopDots" matrix={[[1,1,1,1,1,1,1,1,1,1], [1,1,1,1,1,1,1,1,1,1], [1,1,1,1,1,1,1,1,1,1]]} />
            </div>
            <div className={classes.desktopDots}>
              <Dots id="serviceDesktopDots" matrix={[[1,1,1,1,1,1,1,1,1,1,1], [1,1,1,1,1,1,1,1,1,1,1], [1,1,1,1,1,1,1,1,1,1,1]]} />
            </div>
          </div>
          <div className={classes.wrapperRight}>
            <div className={classes.serviceGroup}>
              {services.map((service:IService, index:number) => (
                <div
                  key={`service-item-${index}`}
                  className={classes.serviceItem}
                >
                  <div className={classes.serviceItemIcon}>
                    <Icon icon={service.icon} size="large" />
                  </div>
                  <h4 className={classes.serviceItemLabel}>{service.label}</h4>
                </div>
              ))}
            </div>
            <div className={classes.mobileDots}>
              <Dots id="serviceMobileDots" matrix={[[1,1,1,1,1,1,1,1], [1,1,1,1,1,1,1,1]]} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhoWeAre;
