import React, { Fragment, useState } from 'react';
// Styles
import classes from './styles.module.scss';
// Assets
import Img1 from './1.jpg';
import Img2 from './2.jpg';
import Img3 from './3.jpg';
import Img4 from './4.jpg';
import ImageDialog from 'dialogs/ImageDialog';

const Workspace:React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleOpenDialog = (img: string) => setSelectedImage(img);
  const handleCloseDialog = () => setSelectedImage(null);

  return (
    <Fragment>
      {selectedImage ? (
        <ImageDialog onClose={handleCloseDialog} image={selectedImage} />
      ) : null}

      <div className={classes.root}>
        <div className={`container ${classes.container}`}>
          <div className={classes.text}>
            <h2>Office design gallery</h2>
            {/*<p>Explore our captivating office space that combines awesome design elements with the latest technologies.</p>*/}
          </div>
          <div className={classes.images}>
            <div className={classes.imageWrapper} onClick={() => handleOpenDialog(Img1)}>
              <div className={classes.imageHover} />
              <img src={Img1} alt="" className={classes.image} />
            </div>
            <div className={classes.imageWrapper} onClick={() => handleOpenDialog(Img2)}>
              <div className={classes.imageHover} />
              <img src={Img2} alt="" className={classes.image} />
            </div>
            <div className={classes.imageWrapper} onClick={() => handleOpenDialog(Img4)}>
              <div className={classes.imageHover} />
              <img src={Img4} alt="" className={classes.image} />
            </div>
            <div className={classes.imageWrapper} onClick={() => handleOpenDialog(Img3)}>
              <div className={classes.imageHover} />
              <img src={Img3} alt="" className={classes.image} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Workspace;
