import classes from './styles.module.scss';
import BannerImage from './banner.png';

import React from 'react';
// Components
import Dots from 'components/Dots';
// Hooks
import useScrollIntoView from 'hooks/useScrollIntoView';

const Banner:React.FC = () => {
  const { scrollTo } = useScrollIntoView();

  const handleClick = (containerName:string) => {
    scrollTo(containerName)
  }

  return (
    <div className={classes.root} data-container="banner">
      <div className={`container ${classes.container}`}>
        <div className={classes.bannerText}>
          <h1 className={`title ${classes.title}`}>Put the focus <br />on <span className="highlight">right things.</span></h1>
          <p className={`text ${classes.text}`}>Let us digitize your business and convert your multi years experience into online services to be used by many. We provide custom solutions for business in the era of big data.</p>
          <div className={classes.buttonGroup}>
            <button
              title='Go to Contact Us form'
              className="button button-contained"
              onClick={() => handleClick('contact-us')}
            >Contact us</button>
            <button
              title='Go to About Us section'
              className="button button-outlined"
              onClick={() => handleClick('about')}
            >Learn more</button>
          </div>
        </div>
        <div className={classes.bannerImageWrapper}>
          <img className={`image-fluid ${classes.bannerImage}`} src={BannerImage} alt="" />
          <div className={classes.mobileDots}>
            <Dots id="bannerMobileDots" matrix={[[1,1,1,1,0,0], [1,1,1,1,1,1], [1,1,1,1,1,1]]} />
          </div>
          <div className={classes.tabletDots}>
            <Dots id="bannerTabletDots" matrix={[[1,1,1,1,1,0,0], [1,1,1,1,1,1,1], [1,1,1,1,1,1,1]]} />
          </div>
          <div className={classes.desktopDots}>
            <Dots id="bannerDesktopDots" matrix={[[1,1,1,0,0], [1,1,1,1,1], [1,1,1,1,1]]} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner;
