import React from 'react';
// Styles
import classes from './styles.module.scss';
// Assets
import Image from './image.jpg';

const About:React.FC = () => {
  return (
    <div className={classes.root}>
      <div className={`big-container ${classes.container}`}>
        <div className={classes.text}>
          <h2>Modern Comfort Nexus</h2>
          <p>Place where the calm ambiance of natural materials effortlessly blends with contemporary elements, creating an environment that cultivates both productive work and relaxation.</p>
        </div>
        <img src={Image} alt="" className={classes.image} />
      </div>
    </div>
  )
}

export default About;
