import { FC } from 'react';

export type IconTypes = 'ArrowsClockwise' | 'BigData' | 'ChartLineUp' | 'Check' | 'CloudCheck' |
  'Code' | 'Cookie' | 'Cube' | 'Desktop' | 'DeviceMobile' | 'Gear' | 'Heartbeat' |
  'HouseLine' | 'Laptop' | 'Lightbulb' | 'LinkedIn' | 'MagnifyingGlass' | 'ShoppingCart' |
  'Stack' | 'UmbrellaSimple' | 'ChevronLeftOutlined' | 'ChevronRightOutlined' | 'ExpandMoreFilled' |
  'Close' | 'MenuFilled'
;

type Props = {
  className?: string;
  icon: IconTypes;
  size?: 'default' | 'large';
};

const Icon:FC<Props> = ({
  // Props
  className = '', icon, size = 'default'
}) => {
  const sizeValue = size === 'default' ? 24 : 32;
  return (
    <div
      className={className}
      style={{
        width: `${sizeValue}px`,
        height: `${sizeValue}px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: `${sizeValue}px`
      }}
    ><i className={`icon icon-${icon}`}></i></div>
  )
}

export default Icon;
