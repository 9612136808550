import { FC, Fragment } from 'react';
// 
import BannerBlock from './BannerBlock';
import AboutBlock from './AboutBlock';
// import WhyUsBlock from './WhyUsBlock';
import ServicesBlock from './ServicesBlock';
import ProjectsBlock from './ProjectsBlock';
import TrustUsBlock from './TrustUsBlock';
import ContactUsBlock from './ContactUsBlock';

const HomePage:FC = () => {
  return (
    <Fragment>
      <BannerBlock />
      <AboutBlock />
      {/* <WhyUsBlock /> */}
      <ServicesBlock />
      <ProjectsBlock />
      <TrustUsBlock />
      <ContactUsBlock />
    </Fragment>
  );
}

export default HomePage;
