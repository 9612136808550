import classes from './Cookies.module.scss';

import { FC, useState } from 'react';
import Icon from 'components/Icon';
// Services
import { getCookies, setCookies, deleteCookies } from 'services/Cookies.service'

const Cookie:FC = () => {
  const cookies = getCookies();
  const [ show, setShow ] = useState(!Boolean(cookies));

  const handleClick = () => {
    deleteCookies();
    setCookies();
    setShow(false)
  };

  if ( !show ) return null;
  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <div className={classes.iconWrapper}>
          <Icon size="large" icon="Cookie" />
        </div>
        <p className={classes.text}>We use cookies to improve user experience, analyze website traffic and assist in our marketing efforts. By clicking “Accept“, you agree to our website's cookie use.</p>
      </div>
      <div>
        <button
          className={`button button-contained ${classes.button}`}
          onClick={handleClick}
          title='Accept website&apos;s cookies use'
        >Accept all</button>
      </div>
    </div>
  )
}

export default Cookie;
