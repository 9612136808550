import classes from './styles.module.scss';

import { FC, Fragment, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
// Components
import { Input, TextArea } from 'components/Controls';
import Dots from 'components/Dots';
// Dialogs
import SuccessDialog from 'dialogs/SuccessDialog';
// Utiltiies
import { isRequired, isEmail } from 'utilities/Validation';

const apiURL:string = 'https://eu-central-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/mouseovercorporatesite-paoft/service/http/incoming_webhook/send_contact_email';

type IFormValue = {
  name: string;
  email: string;
  phone?: string;
  company?: string;
  message: string;
}

const ContactUs:FC = () => {
  const [ open, setOpen ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const { control, handleSubmit, formState: { errors, isDirty }, reset } = useForm<IFormValue>({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      company: '',
      message: ''
    }
  });

  const onSubmit = handleSubmit(async (data:IFormValue) => {
    const { phone, company, ...otherData } = data;
    const nextData:IFormValue = otherData;
    if ( phone ) nextData['phone'] = phone;
    if ( company ) nextData['company'] = company;
    setLoading(true);
    try {
      const response:Response = await fetch(apiURL, {
        method: 'POST',
        body: JSON.stringify(nextData),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if ( !response.ok ) throw new Error('Something went wrong');
      toggleDialog();
      reset();
    } catch(error){
      console.error(error);
    } finally {
      setLoading(false);
    }
  });

  const toggleDialog = () => setOpen(!open);

  return (
    <Fragment>
      <div className={classes.root} data-container="contact-us">
        <div className={`container ${classes.container}`}>
          <h3 className="subtitle text-center">LET’S TALK</h3>
          <h2 className={`title text-center ${classes.title}`}>
            <span className="highlight">Contact us</span> for the service <span className="block">you want to use.</span>
          </h2>
          <form className={classes.form} onSubmit={onSubmit} noValidate>
            <div className={`${classes.col}`}>
              <Controller
                control={control} name="name" rules={{ required: isRequired }}
                render={({ field }) => (
                  <Input
                    {...field}
                    label="Name" id="formName" name="name" placeholder="Your Name"
                    required={true}
                    error={Boolean(errors.name)}
                    helperText={errors.name ? errors.name.message : ''}
                  />
                )}
              />
              <Controller
                control={control} name="phone"
                render={({ field }) => (
                  <Input
                    {...field}
                    label="Phone number" id="formPhone" name="phone" placeholder="(XXX) XXX XX XX"
                  />
                )}
              />
            </div>
            <div className={`${classes.col}`}>
              <Controller
                control={control} name="email" rules={{ required: isRequired, pattern: isEmail }}
                render={({ field }) => (
                  <Input
                    {...field}
                    label="Email address" id="formEmail" name="email" type="email" placeholder="example@email.com"
                    required={true}
                    error={Boolean(errors.email)}
                    helperText={errors.email ? errors.email.message : ''}
                  />
                )}
              />
              <Controller
                control={control} name="company"
                render={({ field }) => (
                  <Input
                    {...field}
                    label="Company" id="formCompany" name="company" placeholder="Your Company"
                  />
                )}
              />
            </div>
            <div className={`${classes.col} ${classes.colTextArea}`}>
              <Controller
                control={control} name="message" rules={{ required: isRequired }}
                render={({ field }) => (
                  <TextArea
                    {...field}
                    label="Tell us how can we help you" id="formMessage" name="message" placeholder="Your Message"
                    rows={6}
                    required={true}
                    error={Boolean(errors.message)}
                    helperText={errors.message ? errors.message?.message : ''}
                  />
                )}
              />
              <div className={classes.submitWrapper}>
                <button
                  title='Submit Mouseover.io contact form'
                  className={`button button-contained ${classes.submit}`}
                  type="submit"
                  disabled={!isDirty || loading}
                >{loading ? <div className="loader"></div> : 'Send message'}</button>
              </div>
            </div>
          </form>
          <div className={classes.mobileDots}>
            <Dots id="contactUsMobileDots" matrix={[[1,1,1,1,1], [1,1,1,1,1]]} />
          </div>
          <div className={classes.desktopDots}>
            <Dots id="contactUsDesktopDots" matrix={[[1,1,1,1,1,1,1,1], [1,1,1,1,1,1,1,1]]} />
          </div>
        </div>
      </div>
      {open ? (
        <SuccessDialog
          open={open}
          onClose={toggleDialog}
        />
      ) : null}
    </Fragment>
  )
}

export default ContactUs;
