import classes from './styles.module.scss';
import EntanglemediaLogo from './Entanglemedia.svg';
import QuizWizardsLogo from './QuizWizards.svg';
import SynoLogo from './Syno.svg';
import EnreachLogo from './Enreach.svg';
import CaseChronologyLogo from './CaseChronology.svg';

import { FC } from 'react';
import Dots from 'components/Dots';

interface ICustomer {
  href: string;
  imageSrc: any;
  alt: string;
}

const customers:ICustomer[] = [
  { href: 'https://www.linkedin.com/company/entangle-media/', imageSrc: EntanglemediaLogo, alt: 'Entanglemedia' },
  { href: 'https://www.linkedin.com/company/quizwizards', imageSrc: QuizWizardsLogo, alt: 'QuizWizard' },
  { href: 'https://www.linkedin.com/company/synoint/', imageSrc: SynoLogo, alt: 'Syno' },
  { href: 'https://www.casechronology.com/', imageSrc: CaseChronologyLogo, alt: 'CaseChronology' },
  { href: 'https://www.linkedin.com/company/enreach', imageSrc: EnreachLogo, alt: 'Enreach' },
];

const TrustUs:FC = () => {
  return (
    <div className={classes.root} data-container="trust-us">
      <div className={`container ${classes.container}`}>
        <h3 className="subtitle">HAPPY CUSTOMERS</h3>
        <h2 className="title">
          They <span className="highlight">trust us.</span>
        </h2>
        <div className={classes.customerGroup}>
          {customers.map((customer:ICustomer) => (
            <a
              key={`customer-item-${customer.alt}`}
              className={classes.customerItem}
              href={customer.href}
              target="_blank"
              rel="noreferrer"
            >
              <img className={classes.customerItemImage} src={customer.imageSrc} alt={customer.alt} />
            </a>
          ))}
        </div>
        <div className={classes.mobileDots}>
          <Dots id="trustUsMobileDots" matrix={[[1,1,1,1,1,1], [1,1,1,1,1,1]]} />
        </div>
        <div className={classes.desktopDots}>
          <Dots id="trustUsDesktopDots" matrix={[[1,1,1,1,1,1], [1,1,1,1,1,1], [1,1,1,1,1,1], [1,1,1,1,1,1]]} />
        </div>
      </div>
    </div>
  )
}

export default TrustUs;
