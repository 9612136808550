import { FC, Fragment, useEffect } from 'react';
//
import Banner from './BannerBlock';
import About from './AboutBlock';
import Gallery from './GalleryBlock';
import Benefits from './BenefitsBlock';
import Tips from './TipsBlock';

const OfficePage:FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Fragment>
      <Banner />
      <About />
      <Gallery />
      <Benefits />
      <Tips />
    </Fragment>
  );
}

export default OfficePage;
