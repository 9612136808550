import classes from './Dots.module.scss';

import { FC } from "react";

type Props = {
  id: string;
  matrix: number[][],
}

const Dots:FC<Props> = ({ id, matrix }) => {
  return (
    <div className={classes.root}>
      {matrix.map((row:number[], index:number) => (
        <div key={`${id}-dot-row-${index}`} className={classes.dotWrapper}>
          {row.map((dot:number, idx:number) => (
            <span key={`${id}-dot-row-${index}-item-${idx}`} className={`${classes.dot} ${!dot ? classes.dotEmpty : ''}`} />
          ))}
        </div>
      ))}
    </div>
  )
}

export default Dots;
