import { ChangeEvent, forwardRef } from 'react';

type DefaultProps = {
  label?: string;
  name: string;
  id: string;
  type?: 'text' | 'email';
  value: string | undefined;
  placeholder?: string;
  required?: boolean;
  error?: boolean;
  helperText?: string;
}

type InputProps = DefaultProps & {
  type?: 'text' | 'email';
  onChange: (event:ChangeEvent<HTMLInputElement>) => void;
};

export const Input = forwardRef<any, InputProps>(({
  // Props
  label, error, helperText, ...props
}, ref) => {
  return (
    <div className={`control-group ${error ? 'has-error' : ''}`}>
      {label ? <label className="control-label" htmlFor={props.id}>{label}{props.required ? ' *': null}</label> : null}
      <input className="control" ref={ref} {...props} />
      {helperText ? <span className="control-helper">{helperText}</span> : null}
    </div>
  )
});

type TextAreaProps = DefaultProps & {
  rows?: number;
  onChange: (event:ChangeEvent<HTMLTextAreaElement>) => void;
};

export const TextArea = forwardRef<any, TextAreaProps>(({
  // Props
  label, error, helperText, ...props
}, ref) => {
  return (
    <div className={`control-group ${error ? 'has-error' : ''}`}>
      {label ? <label className="control-label" htmlFor={props.id}>{label}{props.required ? ' *': null}</label> : null}
      <textarea className="control" ref={ref} {...props}></textarea>
      {helperText ? <span className="control-helper">{helperText}</span> : null}
    </div>
  )
});
