const COOKIE_NAME:string = 'ck_consent';

export const getCookies = () => {
  const name = `${COOKIE_NAME}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for ( let i = 0; i < ca.length; i++ ){
    let c = ca[i];
    while (c.charAt(0) === ' '){
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0){
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export const setCookies = () => {
  const date = new Date();
  const time = date.getTime();
  date.setTime(time + (30 * 24 * 60 * 60 * 1000));
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${COOKIE_NAME}=${time};${expires};path=/`;
}

export const deleteCookies = () => {
  const date = new Date();
  date.setTime(date.getTime() + (24 * 60 * 60 * 1000));
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${COOKIE_NAME}=;${expires};path=/`;
}
