import classes from './styles.module.scss';

import { FC, Fragment } from 'react';
import Icon from 'components/Icon';

type Props = {
  open: boolean;
  onClose: () => void;
}

const SuccessDialog:FC<Props> = ({
  // Props
  open, onClose
}) => {
  if ( !open ) return null;
  return (
    <Fragment>
      <div className={classes.backdrop}></div>
      <div className={classes.dialog}>
        <div className={classes.dialogContent}>
          <button
            title='Close dialog'
            className={`button button-icon ${classes.dialogCloseButton}`}
            onClick={onClose}
          ><Icon icon="Close" /></button>
          <div className={classes.iconWrapper}>
            <Icon size="large" icon="Check" />
          </div>
          <h3 className={classes.title}>Your message has been sent</h3>
          <p className={`text ${classes.text}`}>We will contact you as soon as possible</p>
          <div className={classes.buttonWrapper}>
            <button
              title='Close dialog'
              className="button button-contained"
              onClick={onClose}
            >OK, thanks!</button>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default SuccessDialog;
