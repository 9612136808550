import classes from './styles.module.scss';
import projects from './projects.json';

import { FC, useState } from 'react';
import Icon from 'components/Icon';

const Projects:FC = () => {
  const [ selectedIndex, setSelectedIndex ] = useState(0);
  const [ selectedProject, setSelectedProject ] = useState(projects[0]);

  const handleNextIndex = (isNext:boolean = false) => {
    const nextIndex = selectedIndex + (isNext ? 1 : -1);
    setSelectedIndex(nextIndex);
    setSelectedProject(projects[nextIndex]);
  }

  return (
    <div className={classes.root} data-container="projects">
      <div className={`container ${classes.container}`}>
        <div className={classes.projectImages}>
          <div className={classes.projectImagesWrapper}>
            <img className={classes.desktopImage} src={require(`${selectedProject.desktopImage}`)} alt={selectedProject.title} />
            {selectedProject.mobileImage ? (
              <img className={classes.mobileImage} src={require(`${selectedProject.mobileImage}`)} alt={selectedProject.title} />
            ) : null}
          </div>
          <div className={classes.navGroupMobile}>
            <button
              title='Show previous success story'
              className={`${classes.navButton}`}
              disabled={selectedIndex === 0}
              onClick={() => handleNextIndex()}
            ><Icon icon="ChevronLeftOutlined" /></button>
            <button
              title='Show next success story'
              className={`${classes.navButton}`}
              disabled={selectedIndex === projects.length - 1}
              onClick={() => handleNextIndex(true)}
            ><Icon icon="ChevronRightOutlined" /></button>
          </div>
        </div>
        <div className={classes.projectInfo}>
          <h3 className="subtitle">SUCCESS STORIES</h3>
          <h2 className="title">
            Our latest <span className="highlight">projects.</span>
          </h2>
          <span className={classes.divider}></span>
          <h3 className={classes.projectTitle}>{selectedProject.title}</h3>
          <p className={`text ${classes.projectText}`}>{selectedProject.text}</p>

          <div className={classes.navGroupDesktop}>
            <button
              title='Show previous success story'
              className={`${classes.navButton}`}
              disabled={selectedIndex === 0}
              onClick={() => handleNextIndex()}
            ><Icon icon="ChevronLeftOutlined" /></button>
            <button
              title='Show next success story'
              className={`${classes.navButton}`}
              disabled={selectedIndex === projects.length - 1}
              onClick={() => handleNextIndex(true)}
            ><Icon icon="ChevronRightOutlined" /></button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Projects;
