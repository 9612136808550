import classes from './Footer.module.scss';
import { FC } from 'react';
import Icon from 'components/Icon';
import { NavLink, useLocation } from 'react-router-dom';

const Footer:FC = () => {
  const { pathname } = useLocation();

  return (
    <footer className={classes.root}>
      <div className={`container ${classes.container}`}>
        <div className={classes.copy}>
          &copy; 2019–{new Date().getFullYear()} MouseOver.&nbsp;
          <span>All rights reserved.</span>
        </div>
        <div className={classes.links}>
          {pathname !== '/office' && (
            <NavLink to="/office" className={classes.link}>Our Office</NavLink>
          )}
          <a title='Our Linked.in page' className={classes.link} href="https://www.linkedin.com/company/mouse-over" target="_blank" rel="noreferrer">
            <Icon icon="LinkedIn" />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
